import Vue from "vue";
import App from "./App";
import router from "./router";

import Antd from "./lib/antd";

import http from "./lib/axios";
import common from "./lib/common";
import loading from "./lib/loading"; // 引入loading

Vue.use(Antd);
Vue.use(loading);

Vue.prototype.$http = http;
Vue.prototype.$common = common;

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  components: { App },
  template: "<App/>",
});
