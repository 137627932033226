<template>
  <div class="userEntrance">
    <div class="nav">
      <div class="nav_item">
        用户名：
        <a-select
          v-model="userLoginName"
          placeholder="请选择用户名"
          class="select-tag"
          allowClear
          show-search
          :filter-option="false"
          option-filter-prop="children"
          @search="searchUserName"
          @change="query()"
        >
          <a-select-option
            v-for="i in UserLoginNameList"
            :key="i.id"
            :value="i.loginName"
            >{{ i.userName }}</a-select-option
          >
        </a-select>
      </div>
      <div class="nav_item">
        入口：
        <a-select
          v-model="entranceId"
          placeholder="请输入入口"
          class="select-tag"
          allowClear
          @change="query()"
        >
          <a-select-option
            v-for="i in entranceList"
            :key="i.id"
            :value="i.id"
            >{{ i.name }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="userList-search-button">
      <a-button type="primary" @click="query(1)">查询</a-button>
      <i style="margin-left: 20px"></i>
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDateSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="entranceName" slot-scope="text, record">
        {{ text }} |
        {{
          record.environment == "test"
            ? "测试"
            : record.environment == "prod"
            ? "正式"
            : ""
        }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-button
          type="link"
          @click="deleteClick(record)"
          style="padding: 0; color: red"
        >
          删除
        </a-button>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="用户登录名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="userLoginNameAdd"
            placeholder="请选择用户"
            class="select-tag"
            allowClear
            show-search
            :filter-option="false"
            option-filter-prop="children"
            @search="searchUserName"
          >
            <a-select-option
              v-for="i in UserLoginNameList"
              :key="i.id"
              :value="i.loginName"
              >{{ i.userName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="绑定用户名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-model="bindUserLoginNameAdd"
            placeholder="请输入绑定用户名"
          />
        </a-form-item>
        <a-form-item
          label="入口:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="entranceIdAdd"
            placeholder="请输入入口"
            class="select-tag"
            allowClear
            @keyup.enter="query(1)"
            mode="multiple"
            v-if="title == '新增'"
          >
            <a-select-option
              v-for="i in entranceList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
          <a-select
            v-model="entranceIdAdd"
            placeholder="请输入入口"
            class="select-tag"
            allowClear
            @keyup.enter="query(1)"
            v-else
          >
            <a-select-option
              v-for="i in entranceList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="排序:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="orderAdd" placeholder="请输入排序" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/userEntrance.js";

export default {
  name: "userEntrance",
  data() {
    return {
      title: "",
      userLoginName: undefined,
      userLoginNameAdd: undefined,
      UserLoginNameList: [],
      entranceId: undefined,
      entranceList: [],
      favorite: undefined,
      favoriteAdd: undefined,
      entranceIdAdd: undefined,
      bindUserLoginName: "",
      bindUserLoginNameAdd: "",
      order: undefined,
      orderAdd: undefined,
      favoriteList: [],
      groupId: "",
      addKeyVisible: false,
      editKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "入口名称",
          dataIndex: "entranceName",
          scopedSlots: { customRender: "entranceName" },
        },
        {
          title: "用户名",
          dataIndex: "userName",
        },
        {
          title: "用户登录名",
          dataIndex: "userLoginName",
        },
        {
          title: "绑定用户名",
          dataIndex: "bindUserLoginName",
        },
        {
          title: "排序",
          dataIndex: "order",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDateSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
    this.getUserList();
    this.getEntranceList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        userLoginName: this.userLoginName,
        entranceId: this.entranceId,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDateSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.userLoginNameAdd = val.userLoginName;
      this.favoriteAdd = val.favorite;
      this.entranceIdAdd = val.entranceId;
      this.bindUserLoginNameAdd = val.bindUserLoginName;
      this.orderAdd = val.order;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.userLoginNameAdd = undefined;
      this.favoriteAdd = undefined;
      this.entranceIdAdd = undefined;
      this.bindUserLoginNameAdd = "";
      this.orderAdd = "1";
    },
    // 确定新增
    add_submit() {
      let data = {
        userLoginName: this.userLoginNameAdd,
        bindUserLoginName: this.bindUserLoginNameAdd,
        order: this.orderAdd,
      };
      if (this.title === "新增") {
        data.entranceIdList = this.entranceIdAdd;
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.entranceId = this.entranceIdAdd;
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.userLoginNameAdd = "";
      this.favoriteAdd = undefined;
      this.entranceIdAdd = undefined;
      this.bindUserLoginNameAdd = "";
      this.orderAdd = "";
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确定要删除?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          api.deleteData(data).then((res) => {
            console.log(res);
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 获取favorite集合
    getfavoriteList() {
      let data = {
        param: "",
      };
      api.favoriteList(data).then((res) => {
        if (res.result === 200) {
          this.favoriteList = res.data;
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    searchUserName(val) {
      this.UserLoginNameList.splice(0);
      this.getUserList(val);
    },
    // 查询用户列表
    getUserList(val) {
      let data = {
        pageNo: 1,
        pageSize: 500,
        enabled: true,
        userLoginName: val,
      };
      this.$http.post("/manager/userList", data).then((res) => {
        if (res.result === 200) {
          this.UserLoginNameList.push(...res.data.records);
        }
      });
    },
    // 查询入口列表
    getEntranceList() {
      this.$http.get("/entrance/entranceList").then((res) => {
        if (res.result === 200) {
          this.entranceList.push(...res.data);
          this.entranceList.forEach((item) => {
            this.$set(
              item,
              "name",
              item.name +
                " | " +
                (item.environment == "test"
                  ? "测试"
                  : item.environment == "prod"
                  ? "正式"
                  : "")
            );
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.userEntrance {
  background-color: #fff;
  padding: 20px;
}
</style>
