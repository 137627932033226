<template>
  <div class="nav">
    <a-tabs v-model="accountTabsKey" @change="callbackAccount">
      <a-tab-pane key="user">
        <span slot="tab"> 系统用户 </span>
        <div class="btn-c">
          <a-button type="primary" @click="inquire(1)">查询</a-button>
          <i></i>
          <a-button type="primary" @click="addUser">添加用户</a-button>
          <i></i>
          <a-button @click="syncWechatUserClick">同步用户信息</a-button>
          <i></i>
          <a-checkbox v-model="checkedEnabled">过滤已禁用</a-checkbox>
        </div>
        <!-- 用户列表 -->
        <a-table
          :columns="userColumns"
          :dataSource="userTableData"
          :pagination="userPagination"
          :loading="tableLoading"
          size="middle"
          :rowKey="(record) => record.id"
        >
          <span slot="userName" slot-scope="text, record" style="width: 100%">
            <a-avatar
              class="avatar"
              size="small"
              shape="circle"
              :src="record.avatar"
              v-if="record.avatar"
            />
            <span>{{ text }}</span>
          </span>
          <span slot="enabled" slot-scope="text, record" style="width: 100%">
            <a-switch
              checkedChildren="启用"
              unCheckedChildren="禁用"
              v-model="record.enabled"
              @change="enabledChange($event, record)"
              :loading="loading"
            />
          </span>
          <span slot="action" slot-scope="text, record" style="width: 100%">
            <a @click="bindClient(record)">客户端分组绑定</a>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="dingding">
        <span slot="tab">
          钉钉登录
          <!-- <a href="#dingding" id="dingding">钉钉登录</a> -->
        </span>
        <!-- 钉钉登录 -->
        <a-table
          :columns="dingColumns"
          :dataSource="dingTableData"
          :pagination="dingPagination"
          size="middle"
        >
          <span slot="action" slot-scope="text, record" style="width: 100%">
            <a @click="adoptClick(record)" style="color: #52c41a">通过</a>
            <a-divider type="vertical" />
            <a @click="failClick(record)" style="color: #ff5500">拒绝</a>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 通过钉钉 -->
    <a-modal
      title="确认通过"
      v-model="adoptShow"
      :maskClosable="false"
      :afterClose="adoptClose"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="昵称"
        >
          <span>{{ dingtalkNick }}</span>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="登录名称"
        >
          <a-input v-model="loginName" placeholder="请输入登录名称"></a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="adoptShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="adopt_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 添加用户 -->
    <a-modal
      title="添加用户"
      v-model="addUserShow"
      :maskClosable="false"
      :afterClose="addUserClose"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="用户名称"
          required
        >
          <a-input
            v-model="addUserForm.name"
            placeholder="请输入用户名称"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="登录名称"
          required
        >
          <a-input
            v-model="addUserForm.userid"
            placeholder="请输入登录名称"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="手机号"
          required
        >
          <a-input
            v-model="addUserForm.mobile"
            placeholder="请输入手机号"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="性别"
          required
        >
          <a-radio-group v-model="addUserForm.gender">
            <a-radio :value="1">男性</a-radio>
            <a-radio :value="2">女性</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addUserShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="绑定客户端分组"
      v-model="bindClientShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端分组"
        >
          <a-select
            v-model="clientGroupId"
            placeholder="请选择客户端分组"
            show-search
            :filter-option="filterOption"
            option-filter-prop="children"
          >
            <a-select-option v-for="item in clientGroupList" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="bindClientShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="bind_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "userlist",
  data() {
    return {
      tableLoading: false,
      checkedEnabled: true,
      addUserForm: {
        gender: 1,
        department: [1],
        mobile: "",
        name: "",
        userid: "",
      },
      addUserShow: false,
      adoptShow: false,
      loginName: "",
      dingtalkNick: "",
      dingId: "",
      accountTabsKey: "user",
      userColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "用户名称",
          dataIndex: "userName",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "登录名称",
          dataIndex: "loginName",
        },
        {
          title: "客户端分组",
          dataIndex: "clientGroupName",
        },
        {
          title: "上次登录时间",
          dataIndex: "lastLoginDate",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "状态",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userTableData: [],
      userPagination: {},
      currentPage: 1,
      loading: false,
      dingColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "key",
          width: 60,
        },
        {
          title: "昵称",
          dataIndex: "dingtalkNick",
        },
        {
          title: "openId",
          dataIndex: "openId",
        },
        {
          title: "申请时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dingTableData: [],
      dingPagination: {},
      unifiedLoading: null,
      bindClientShow: false,
      id: "",
      clientGroupId: "",
      clientGroupList: [],
    };
  },
  mounted() {
    document.title = "后台管理-用户管理";
    this.getClientGroupList();
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getClientGroupList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$http.post("/client/clientGroupList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.clientGroupList = list;
          this.inquire(1);
        }
      });
    },
    // 点击同步用户信息
    syncWechatUserClick() {
      this.unifiedLoading = this.$loading({ message: "用户信息同步中..." });
      this.$http.get("/user/syncWechatUser").then((res) => {
        if (res.result === 200) {
          this.$message.success("用户信息同步成功");
          this.unifiedLoading.close();
        }
      });
    },
    // 点击添加用户
    addUser() {
      this.addUserShow = true;
    },
    // 确定添加用户
    addUser_submit() {
      if (!this.addUserForm.name) {
        this.$message.warning("请输入用户名称");
        return;
      }
      if (!this.addUserForm.userid) {
        this.$message.warning("请输入登录名称");
        return;
      }
      if (!this.addUserForm.mobile) {
        this.$message.warning("请输入手机号");
        return;
      }
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.addUserForm.mobile)) {
        this.$message.warning("手机号码有误，请重填");
        return false;
      }
      let data = this.addUserForm;
      this.$http.json_post("/sys/createMember", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.addUserShow = false;
          this.inquire(1);
        }
      });
    },
    // 关闭添加用户
    addUserClose() {
      this.addUserForm = {
        gender: 1,
        department: [1],
        mobile: "",
        name: "",
        userid: "",
      };
    },
    // 点击切换tab
    callbackAccount(key) {
      this.currentPage = 1;
      if (key === "user") {
        this.inquire(1);
      }
      if (key === "dingding") {
        this.inquireDing(1);
      }
    },
    // 查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
      };
      if (this.checkedEnabled) {
        data.enabled = true;
      }
      this.getUserList(data);
    },
    // 用户分页
    changePage(current) {
      this.inquire(current);
    },
    bindClient(val) {
      this.id = val.id;
      if (val.clientGroupId !== -1) {
        this.clientGroupId = val.clientGroupId;
      } else {
        this.clientGroupId = undefined;
      }
      this.bindClientShow = true;
    },
    bind_submit() {
      let data = {
        id: this.id,
        clientGroupId: this.clientGroupId,
      };
      this.$http
        .json_post("/manager/updateUserClientGroup", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.bindClientShow = false;
            this.inquire(1);
          }
        });
    },
    // 状态修改
    enabledChange(e, val) {
      let data = {
        id: val.id,
        enabled: e,
      };
      if (e) {
        // 启用
        this.$confirm({
          title: "提示",
          content: "是否启用？",
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            return new Promise((resolve, reject) => {
              this.loading = true;
              this.$http
                .json_post("/manager/updateUser", data)
                .then((res) => {
                  this.loading = false;
                  if (res.result === 200) {
                    this.inquire();
                    this.$message.success("启用成功");
                    resolve();
                  } else {
                    reject();
                  }
                })
                .catch((err) => {
                  reject();
                });
            }).catch(() => {
              this.loading = false;
            });
          },
          onCancel: () => {
            this.loading = false;
            for (let i in this.userTableData) {
              if (this.userTableData[i].id === val.id) {
                this.$set(this.userTableData[i], "enabled", false);
              }
            }
          },
        });
      } else {
        // 禁用
        this.$confirm({
          title: "提示",
          content: "是否禁用？",
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            return new Promise((resolve, reject) => {
              this.loading = true;
              this.$http
                .json_post("/manager/updateUser", data)
                .then((res) => {
                  this.loading = false;
                  if (res.result === 200) {
                    this.inquire();
                    this.$message.success("禁用成功");
                    resolve();
                  } else {
                    reject();
                  }
                })
                .catch((err) => {
                  reject();
                });
            }).catch(() => {
              this.loading = false;
            });
          },
          onCancel: () => {
            this.loading = false;
            for (let i in this.userTableData) {
              if (this.userTableData[i].id === val.id) {
                this.$set(this.userTableData[i], "enabled", true);
              }
            }
          },
        });
      }
    },
    // 查询用户列表
    getUserList(data) {
      this.tableLoading = true;
      this.$http
        .post("/manager/userList", data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              item.lastLoginDate = this.$common.transformTime(
                item.lastLoginDate
              );
              item.clientGroupName = "";
              this.clientGroupList.forEach((group) => {
                if (group.id == item.clientGroupId) {
                  item.clientGroupName = group.name;
                }
              });
            });
            this.userTableData = list;
            this.userPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePage(current),
            };
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 查询钉钉登录
    inquireDing(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
      };
      this.getDingtalkApplyList(data);
    },
    // 钉钉分页
    dingChangePage(current) {
      this.inquireDing(current);
    },
    // 查询钉钉登录列表
    getDingtalkApplyList(data) {
      this.$http.post("/manager/dingtalkApplyList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
            // item.enabled = item.enabled === "ENABLED" ? true : false;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.dingTableData = list;
          this.dingPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.dingChangePage(current),
          };
        }
      });
    },
    // 点击通过
    adoptClick(val) {
      this.dingId = val.id;
      this.dingtalkNick = val.dingtalkNick;
      this.adoptShow = true;
    },
    // 确认通过
    adopt_submit() {
      let data = {
        id: this.dingId,
        loginName: this.loginName,
        passed: true,
      };
      this.$http.post("/manager/auditDingtalkApply", data).then((res) => {
        if (res.result === 200) {
          this.inquireDing();
          this.adoptShow = false;
          this.$message.success("操作成功");
        }
      });
    },
    // 关闭确认通过
    adoptClose() {
      this.dingtalkNick = "";
      this.loginName = "";
      this.dingId = "";
    },
    // 点击不通过
    failClick(val) {
      let data = {
        id: val.id,
        passed: false,
      };
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div>
            <p>昵称: {val.dingtalkNick}</p>
            <p>是否拒绝？</p>
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.$http
              .post("/manager/auditDingtalkApply", data)
              .then((res) => {
                if (res.result === 200) {
                  this.inquireDing();
                  this.$message.success("操作成功");
                  resolve();
                } else {
                  reject();
                }
              })
              .catch((err) => {
                reject();
              });
          }).catch(() => {});
        },
        onCancel: () => {},
      });
    },
  },
};
</script>
<style scoped>
.btn-c {
  margin-bottom: 20px;
}
.btn-c i {
  display: inline-block;
  margin-left: 20px;
}
</style>
