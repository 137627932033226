<template>
  <div class="entranceList">
    <div class="nav">
      <div class="nav_item">
        入口名称：
        <a-input
          placeholder="请输入入口名称"
          v-model="name"
          @keyup.enter="query()"
          allow-clear
        ></a-input>
      </div>
      <div class="nav_item">
        环境：
        <a-select
          v-model="environment"
          placeholder="请选择环境"
          class="select-tag"
          allowClear
          @keyup.enter="query()"
          @change="environmentChange"
        >
          <a-select-option
            v-for="i in environmentList"
            :key="i.id"
            :value="i.type"
            >{{ i.title }}</a-select-option
          >
        </a-select>
      </div>
      <div class="nav_item">
        <a-button type="primary" @click="query()">查询</a-button>
        <i style="margin-left: 20px"></i>
        <a-button type="primary" @click="add">新增</a-button>
      </div>
    </div>
    <div class="entranceCardList">
      <template v-for="item in entranceList">
        <div class="entrance">
          <div class="top">
            <a-button
              type="link"
              class="button"
              @click.stop="deleteClick(item)"
            >
              <a-icon type="delete"></a-icon>
            </a-button>
            <a-button
              type="link"
              class="button"
              @click.stop="modifyClick(item)"
              style="margin-right: 5px"
            >
              <a-icon type="edit"></a-icon>
            </a-button>
          </div>
          <div class="bottom">
            <a-avatar class="img" shape="square" :src="item.icon" :size="60" />
            <div class="name">
              {{ item.name }}
              <div class="environment">
                {{
                  item.environment == "test"
                    ? "测试"
                    : item.environment == "prod"
                    ? "正式"
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        class="remainder"
        v-for="item in 4 - (entranceList.length % 4)"
      ></div>
    </div>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="入口名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入入口名称" />
        </a-form-item>
        <a-form-item
          label="环境:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="environmentAdd"
            placeholder="请选择环境"
            class="select-tag"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="i in environmentList"
              :key="i.id"
              :value="i.type"
              >{{ i.title }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="icon:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-upload
            :file-list="fileList"
            :action="url"
            list-type="picture-card"
            @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item
          label="sso跳转地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="ssoUriAdd" placeholder="请输入sso跳转地址" />
        </a-form-item>
        <a-form-item
          label="跳转地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="redirectUriAdd" placeholder="请输入跳转地址" />
        </a-form-item>
        <a-form-item
          label="是否启用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabledAdd"
          />
        </a-form-item>
        <a-form-item
          label="类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="typeAdd"
            placeholder="请选择类型"
            class="select-tag"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="i in typeList"
              :key="i.id"
              :value="i.type"
              >{{ i.title }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remarkAdd" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/entrance.js";

export default {
  name: "entranceList",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      icon: "",
      iconAdd: "",
      ssoUri: "",
      ssoUriAdd: "",
      redirectUri: "",
      redirectUriAdd: "",
      enabled: undefined,
      enabledAdd: true,
      type: "",
      typeAdd: undefined,
      typeList: [
        { id: 1, type: "SSO_LOGIN", title: "SSO跳转" },
        { id: 2, type: "JUMP_DIRECT", title: "直接跳转" },
      ],
      environmentList: [
        { id: 1, type: "test", title: "测试" },
        { id: 2, type: "prod", title: "正式" },
      ],
      groupId: "",
      addKeyVisible: false,
      loading: false,
      entranceList: [],
      environment: undefined,
      environmentAdd: undefined,
      remarkAdd: undefined,
      url:
        location.protocol + process.env.VUE_APP_BASE_API + "/entrance/addIcon",
      fileList: [],
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    handleChange({ fileList }) {
      this.fileList = fileList;
      if (fileList.length > 0 && fileList[0].response) {
        this.iconAdd = fileList[0].response.data;
      }
    },
    // 点击查询
    query() {
      let data = {
        name: this.name,
        environment: this.environment,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data;
            this.entranceList = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 修改
    modifyClick(val) {
      this.fileList.splice(0);
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.nameAdd = val.name;
      this.iconAdd = val.icon;
      this.fileList.push({
        uid: "1",
        name: "image.png",
        status: "done",
        url: this.iconAdd,
      });
      this.ssoUriAdd = val.ssoUri;
      this.redirectUriAdd = val.redirectUri;
      this.enabledAdd = val.enabled;
      this.typeAdd = val.type;
      this.environmentAdd = val.environment;
      this.remarkAdd = val.remark;
    },
    // 新增
    add() {
      this.fileList.splice(0);
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
      this.iconAdd = "";
      this.ssoUriAdd = "";
      this.redirectUriAdd = "";
      this.enabledAdd = undefined;
      this.typeAdd = undefined;
      this.environmentAdd = undefined;
      this.remarkAdd = undefined;
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
        ssoUri: this.ssoUriAdd,
        redirectUri: this.redirectUriAdd,
        enabled: this.enabledAdd,
        type: this.typeAdd,
        environment: this.environmentAdd,
        remark: this.remarkAdd,
        icon: this.iconAdd,
      };
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.nameAdd = "";
      this.iconAdd = "";
      this.ssoUriAdd = "";
      this.redirectUriAdd = "";
      this.enabledAdd = undefined;
      this.typeAdd = "";
      this.environment = undefined;
      this.environmentAdd = undefined;
      this.remarkAdd = undefined;
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确定要删除?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          api.deleteData(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    environmentChange(val) {
      this.environment = val;
    },
    environmentAddChange(val) {
      this.environmentAdd = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.entranceList {
  background-color: #fff;
  padding: 20px;
  .entranceCardList {
    box-sizing: border-box;
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .entrance {
      width: 23.5%;
      box-sizing: border-box;
      cursor: pointer;
      padding: 10px 15px;
      background: #f1f1f19e;
      height: 130px;
      font-size: 13px;
      border-radius: 5px;
      text-align: center;
      position: relative;
      margin-top: 20px;
      &:hover {
        transform: translate(-2px, -2px) scale(1.01);
        transition: all 0.2s;
        box-shadow: 1px 1px 5px 1px #b9b9b9;
      }
      .top {
        overflow: hidden;
        .button {
          font-size: 14px;
          padding: 0;
          float: right;
          line-height: 16px;
          height: 16px;
        }
      }
      .bottom {
        margin-top: 10px;
        overflow: hidden;
        cursor: pointer;
        height: 80%;
        display: flex;
        .name {
          font-size: 18px;
          font-weight: 700;
          text-align: right;
          padding-top: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: auto;
          .environment {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
    .remainder {
      content: "";
      width: 23.5%;
      height: 0px;
      visibility: hidden;
    }
  }
}
</style>
