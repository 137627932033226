<template>
  <div class="nav">
    <div class="search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <i style="margin-left: 20px"></i>
      <a-button type="primary" @click="addClick">新增</a-button>
    </div>
    <!-- 客户端列表 -->
    <a-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a @click="updateClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="modalTitle"
      v-model="addClientShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="名称"
        >
          <a-input v-model="name" placeholder="请输入名称"></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端"
        >
          <a-select
            v-model="clientIdList"
            placeholder="请选择客户端"
            show-search
            :filter-option="filterOption"
            option-filter-prop="children"
            mode="multiple"
          >
            <a-select-option v-for="item in clientSelectList" :key="item.id">{{
              item.clientName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addClientShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "clientManagementGroup",
  data() {
    return {
      modalTitle: "新增",
      columns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "客户端",
          dataIndex: "clientNameList",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      pagination: {},
      currentPage: 1,
      addClientShow: false,
      name: "",
      clientIdList: [],
      clientSelectList: [],
    };
  },
  mounted() {
    document.title = "后台管理-客户端管理";
    this.getClientList();
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getClientList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$http.post("/client/clientList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.clientSelectList = list;
          this.inquire(1);
        }
      });
    },
    // 查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
      };
      this.getClientGroupList(data);
    },
    // 分页
    changePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 查询列表
    getClientGroupList(data) {
      this.$http.post("/client/clientGroupList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.tableData = list;
          this.tableData.forEach((group) => {
            group.clientNameList = [];
            group.clientIdList.forEach((item) => {
              this.clientSelectList.forEach((element) => {
                if (item == element.id) {
                  group.clientNameList.push(element.clientName);
                }
              });
            });
            group.clientNameList = group.clientNameList.join(",");
          });
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePage(current),
          };
        }
      });
    },
    // 点击新增
    addClick() {
      this.modalTitle = "新增";
      this.addClientShow = true;
      this.name = "";
      this.clientIdList.splice(0);
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.name,
        clientIdList: this.clientIdList,
      };
      if (this.modalTitle == "新增") {
        this.$http.json_post("/client/addClientGroup", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.addClientShow = false;
            this.inquire();
          }
        });
      } else {
        data.id = this.id;
        this.$http.json_post("/client/editClientGroup", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.addClientShow = false;
            this.inquire();
          }
        });
      }
    },
    // 点击修改
    updateClick(val) {
      this.modalTitle = "修改";
      this.id = val.id;
      this.name = val.name;
      this.clientIdList = val.clientIdList;
      this.addClientShow = true;
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/client/deleteClientGroup", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.inquire();
        }
      });
    },
  },
};
</script>
<style scoped>
.randomPswAdd {
  position: absolute;
  right: -66px;
  top: -10px;
}
</style>
