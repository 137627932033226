<template>
  <div class="wechatApplication">
    <!-- <div class="nav">
      <div class="nav-item">
       
      </div>
    </div> -->
    <div class="btn-c">
      <a-button type="primary" @click="query(1)">查询</a-button>
      <a-button @click="addUser" style="margin-left: 20px">新增</a-button>
    </div>
    <!-- 应用列表 -->
    <a-table
      :columns="userColumns"
      :dataSource="userTableData"
      :pagination="userPagination"
      :loading="loadingTable"
      size="middle"
    >
      <span slot="thirdPartLogin" slot-scope="text, record" style="width: 100%">
        <a-tag v-if="text" color="green">启用</a-tag>
        <a-tag v-else color="red">禁用</a-tag>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          @click="deleteClick(record)"
          style="color: #ff4d4f"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="modifyTitle"
      v-model="modifyShow"
      :maskClosable="false"
      :afterClose="modifyClose"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="企业id"
          required
        >
          <a-input
            v-model="modifyForm.corpId"
            placeholder="请输入企业id"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端"
          required
        >
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="modifyForm.clientId"
            placeholder="请选择客户端"
          >
            <a-select-option
              v-for="item in clientIdList"
              :key="item.clientId"
              >{{ item.clientName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="应用名称"
          required
        >
          <a-input
            v-model="modifyForm.name"
            placeholder="请输入应用名称"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="应用id"
          required
        >
          <a-input
            v-model="modifyForm.agentId"
            placeholder="请输入应用id"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="应用秘钥"
          required
        >
          <a-input
            v-model="modifyForm.secret"
            placeholder="请输入应用秘钥"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="跳转地址"
        >
          <a-input
            v-model="modifyForm.indexUrl"
            placeholder="请输入跳转地址"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="三方登录"
        >
          <a-switch
            v-model="modifyForm.thirdPartLogin"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="备注"
        >
          <a-textarea
            v-model="modifyForm.remark"
            placeholder="请输入备注"
            :rows="4"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modify_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "wechatApplication",
  data() {
    return {
      activeId: "",
      loading: false,
      modifyTitle: "",
      modifyForm: {
        corpId: "ww893e8dfd02e2ed76",
        clientId: undefined,
        name: "",
        agentId: "",
        secret: "",
        indexUrl: "",
        remark: "",
        thirdPartLogin: true,
      },
      loadingTable: false,
      userColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "key",
          width: 60,
        },
        {
          title: "应用名称",
          dataIndex: "name",
        },
        {
          title: "应用id",
          dataIndex: "agentId",
        },
        {
          title: "企业id",
          dataIndex: "corpId",
        },
        {
          title: "客户端",
          dataIndex: "clientId",
        },
        {
          title: "应用秘钥",
          dataIndex: "secret",
        },
        {
          title: "授权完成, 跳转此地址",
          dataIndex: "indexUrl",
        },
        {
          title: "三方登录",
          dataIndex: "thirdPartLogin",
          scopedSlots: { customRender: "thirdPartLogin" },
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userTableData: [],
      userPagination: {},
      pageNo: 1,
      modifyShow: false,
      clientIdList: [],
      parentUserLoginNameList: [],
    };
  },
  mounted() {
    this.query(1);
    this.getClientList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.getTableList(data);
    },
    // 查询表格数据
    getTableList(data) {
      this.loadingTable = true;
      this.$http
        .get("/wechatApplication/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.userTableData = list;
            this.userPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    changePage(index) {
      this.query(index);
    },
    // 查询客户端列表
    getClientList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.loadingTable = true;
      this.$http
        .post("/client/clientList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.clientIdList = list;
          }
        })
        .catch((err) => {
          this.loadingTabl = false;
        });
    },
    // 点击新增
    addUser() {
      this.modifyTitle = "新增";
      this.modifyShow = true;
    },
    // 点击修改
    modifyClick(val) {
      this.modifyTitle = "修改";
      this.modifyForm = {
        corpId: val.corpId,
        clientId: val.clientId,
        name: val.name,
        agentId: val.agentId,
        secret: val.secret,
        indexUrl: val.indexUrl,
        remark: val.remark,
        thirdPartLogin: val.thirdPartLogin,
      };
      this.activeId = val.id;
      this.modifyShow = true;
    },
    // 确定新增
    modify_submit() {
      let data = {
        corpId: this.modifyForm.corpId,
        clientId: this.modifyForm.clientId,
        name: this.modifyForm.name,
        agentId: this.modifyForm.agentId,
        secret: this.modifyForm.secret,
        indexUrl: this.modifyForm.indexUrl,
        remark: this.modifyForm.remark,
        thirdPartLogin: this.modifyForm.thirdPartLogin,
      };
      if (!data.corpId) {
        this.$message.warning("请输入企业id");
        return;
      }
      if (!data.clientId) {
        this.$message.warning("请选择客户端");
        return;
      }
      if (!data.agentId) {
        this.$message.warning("请输入应用id");
        return;
      }
      if (!data.secret) {
        this.$message.warning("请输入应用秘钥");
        return;
      }
      this.loading = true;
      if (this.modifyTitle === "新增") {
        this.$http
          .json_post("/wechatApplication/add", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.modifyShow = false;
              this.$message.success("新增成功");
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        data.id = this.activeId;
        this.$http
          .json_post("/wechatApplication/update", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.modifyShow = false;
              this.$message.success("修改成功");
              this.query();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭新增
    modifyClose() {
      this.modifyForm = {
        corpId: "ww893e8dfd02e2ed76",
        clientId: undefined,
        name: "",
        agentId: "",
        secret: "",
        indexUrl: "",
        remark: "",
        thirdPartLogin: true,
      };
    },
    // 选项搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除",
        content: "是否删除【" + val.name + "】应用",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/wechatApplication/delete", { id: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.query();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
  },
};
</script>
<style scoped>
.wechatApplication .btn-c {
  margin-bottom: 20px;
}
</style>
