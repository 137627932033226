<template>
  <div class="nav">
    <div class="search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <i style="margin-left: 20px"></i>
      <a-button type="primary" @click="addClient">新增</a-button>
    </div>
    <!-- 客户端列表 -->
    <a-table
      :columns="clientColumns"
      :dataSource="clientTableData"
      :pagination="clientPagination"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="enableStatus" slot-scope="text, record" style="width: 100%">
        <a-tag v-if="text === 'ENABLED'" color="green">启用</a-tag>
        <a-tag v-if="text !== 'ENABLED'">禁用</a-tag>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a @click="updateClientClick(record)">修改</a>
        <!-- <a-divider type="vertical" /> -->
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="modalTitle"
      v-model="addClientShow"
      :maskClosable="false"
      :afterClose="addClientClose"
    >
      <a-form>
        <a-form-item
          v-if="signClick === 'add'"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="clientId"
        >
          <a-input
            v-model="clientForm.clientId"
            placeholder="请输入clientId"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端名称"
        >
          <a-input
            v-model="clientForm.clientName"
            placeholder="请输入名称"
          ></a-input>
        </a-form-item>
        <a-form-item
          v-if="signClick === 'add'"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端秘钥"
        >
          <a-input
            v-model="clientForm.clientSecret"
            placeholder="请输入秘钥"
          ></a-input>
          <a href="javascript:;" @click="rootPasswordAdd" class="randomPswAdd"
            >随机密码</a
          >
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端描述"
        >
          <a-textarea
            v-model="clientForm.clientDesc"
            placeholder="请输入描述"
            :rows="4"
          ></a-textarea>
        </a-form-item>
        <a-form-item
          v-if="signClick === 'update'"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="状态"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="clientForm.enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addClientShow = false">取消</a-button>
          <a-button
            v-if="signClick === 'add'"
            key="submit"
            type="primary"
            @click="addClient_submit"
            >确定</a-button
          >
          <a-button
            v-if="signClick === 'update'"
            key="submit"
            type="primary"
            @click="updateClient_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "clientManagementList",
  data() {
    return {
      signClick: "add",
      modalTitle: "新增客户端",
      clientColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "clientId",
          dataIndex: "clientId",
        },
        {
          title: "客户端名称",
          dataIndex: "clientName",
        },
        {
          title: "客户端秘钥",
          dataIndex: "clientSecret",
        },
        {
          title: "客户端描述",
          dataIndex: "clientDesc",
        },
        {
          title: "状态",
          dataIndex: "enableStatus",
          scopedSlots: { customRender: "enableStatus" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      clientTableData: [],
      clientPagination: {},
      currentPage: 1,
      addClientShow: false,
      clientForm: {
        clientId: "",
        clientName: "",
        clientSecret: "",
        clientDesc: "",
        enabled: false,
        id: "",
      },
    };
  },
  mounted() {
    document.title = "后台管理-客户端管理";
    this.inquire(1);
  },
  methods: {
    // 查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
      };
      this.getClientList(data);
    },
    // 分页
    changePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 查询列表
    getClientList(data) {
      this.$http.post("/client/clientList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.enabled = item.enableStatus === "ENABLED" ? true : false;
          });
          this.clientTableData = list;
          this.clientPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePage(current),
          };
        }
      });
    },
    // 点击新增
    addClient() {
      this.modalTitle = "新增客户端";
      this.addClientShow = true;
      this.signClick = "add";
    },
    // 确定新增
    addClient_submit() {
      let data = {
        clientId: this.clientForm.clientId,
        clientName: this.clientForm.clientName,
        clientSecret: this.clientForm.clientSecret,
        clientDesc: this.clientForm.clientDesc,
      };
      this.$http.json_post("/client/addClient", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.addClientShow = false;
          this.inquire();
        }
      });
    },
    // 确定修改
    updateClient_submit() {
      let data = {
        id: this.clientForm.id,
        clientName: this.clientForm.clientName,
        clientDesc: this.clientForm.clientDesc,
        enabled: this.clientForm.enabled,
      };
      this.$http.json_post("/client/updateClient", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.addClientShow = false;
          this.inquire();
        }
      });
    },
    // 关闭新增
    addClientClose() {
      this.clientForm = {
        clientName: "",
        clientSecret: "",
        clientDesc: "",
        enabled: false,
        id: "",
        clientId: "",
      };
    },
    // 点击修改
    updateClientClick(val) {
      this.modalTitle = "修改";
      this.addClientShow = true;
      this.signClick = "update";
      this.clientForm.id = val.id;
      this.clientForm.clientDesc = val.clientDesc;
      this.clientForm.enabled = val.enabled;
      this.clientForm.clientName = val.clientName;
    },
    //随机客户端秘钥
    rootPasswordAdd() {
      let psw = this.$common.randomStr(false, 32);
      this.clientForm.clientSecret = psw;
    },
  },
};
</script>
<style scoped>
.randomPswAdd {
  position: absolute;
  right: -66px;
  top: -10px;
}
</style>
