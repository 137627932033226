import Vue from "vue";
import Router from "vue-router";

import Login from "@/components/Login";
import invalid from "@/components/Invalid";
import developing from "@/components/developing";
import servererror from "@/components/servererror";
import menu from "@/components/Menu";
import clientManagement from "@/components/clientManagement";
import clientManagementList from "@/components/clientManagementList";
import clientManagementGroup from "@/components/clientManagementGroup";
import userList from "@/components/userList";
import power from "@/components/power";
import wechatApplication from "@/components/wechatApplication";
import entrance from "@/components/entrance";
import entranceList from "@/components/entranceList";
import userEntranceList from "@/components/userEntranceList";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "menu",
      redirect: "/clientManagement",
      components: {
        first: menu,
      },
      children: [
        {
          path: "/clientManagement",
          name: "clientManagement",
          redirect: "/clientManagement/clientManagementList",
          components: {
            second: clientManagement,
          },
          children: [
            {
              path: "/clientManagement/clientManagementList",
              name: "clientManagementList",
              components: {
                three: clientManagementList,
              },
            },
            {
              path: "/clientManagement/clientManagementGroup",
              name: "clientManagementGroup",
              components: {
                three: clientManagementGroup,
              },
            },
          ],
        },
        {
          path: "/power",
          name: "power",
          components: {
            second: power,
          },
        },
        {
          path: "/wechatApplication",
          name: "wechatApplication",
          components: {
            second: wechatApplication,
          },
        },
        {
          path: "/entrance",
          name: "entrance",
          redirect: "/entrance/userEntranceList",
          components: {
            second: entrance,
          },
          children: [
            {
              path: "/entrance/userEntranceList",
              name: "userEntranceList",
              components: {
                three: userEntranceList,
              },
            },
            {
              path: "/entrance/entranceList",
              name: "entranceList",
              components: {
                three: entranceList,
              },
            },
          ],
        },
        {
          path: "/userList",
          name: "userList",
          components: {
            second: userList,
          },
        },
      ],
    },
    {
      path: "/Login",
      name: "Login",
      components: {
        first: Login,
      },
    },
    {
      path: "/invalid",
      name: "invalid",
      components: {
        first: invalid,
      },
    },
    {
      path: "/servererror",
      name: "servererror",
      components: {
        first: servererror,
      },
    },
    {
      path: "/developing",
      name: "developing",
      components: {
        first: developing,
      },
    },
  ],
});
