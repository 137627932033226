<template>
  <div class="power">
    <a-tabs default-active-key="configPage" @change="callback">
      <a-tab-pane key="configPage" tab="权限管理">
        <power-management v-if="configPageShow"></power-management>
      </a-tab-pane>
      <a-tab-pane key="groupPage" tab="角色管理">
        <power-role v-if="groupPageShow"></power-role>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import powerManagement from "./powerManagement";
import powerRole from "./powerRole";

export default {
  name: "power",
  components: {
    powerManagement,
    powerRole,
  },
  data() {
    return {
      configPageShow: true,
      groupPageShow: false,
    };
  },
  mounted() {},
  methods: {
    callback(key) {
      if (key === "configPage") {
        this.groupPageShow = false;
        this.configPageShow = true;
      }
      if (key === "groupPage") {
        this.configPageShow = false;
        this.groupPageShow = true;
      }
    },
  },
};
</script>
<style  scoped>
</style>