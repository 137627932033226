import http from './axios'

// 查询列表
const queryPage = ({
    userLoginName,
    pageNo,
    pageSize,
    entranceId
}) => {
    return http.get('/userEntrance/userEntrancePage', {
        userLoginName: userLoginName,
        pageNo: pageNo,
        pageSize: pageSize,
        entranceId: entranceId
    })
}

// 添加
const addData = ({
    userLoginName,
    entranceIdList,
    bindUserLoginName,
    order
}) => {
    return http.json_post('/userEntrance/addUserEntrance', {
        userLoginName: userLoginName,
        entranceIdList: entranceIdList,
        bindUserLoginName: bindUserLoginName,
        order: order
    })
}


// 编辑
const editData = ({
    id,
    userLoginName,
    entranceId,
    bindUserLoginName,
    order
}) => {
    return http.json_post('/userEntrance/editUserEntrance', {
        id: id,
        userLoginName: userLoginName,
        entranceId: entranceId,
        bindUserLoginName: bindUserLoginName,
        order:order
    })
}

// 删除
const deleteData = ({
    id
}) => {
    return http.json_post('/userEntrance/deleteUserEntrance', {
        id: id,
    })
}

export default {
    deleteData,
    queryPage,
    addData,
    editData
}