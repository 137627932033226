<template>
  <div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="用户入口列表">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="入口列表">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "entrance",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/entrance/userEntranceList") {
          this.active = "1";
        } else if (newVal == "/entrance/entranceList") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/entrance/userEntranceList") return;
          this.$router.replace("/entrance/userEntranceList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/entrance/entranceList") return;
          this.$router.replace("/entrance/entranceList");
        }
      },
    },
  },
};
</script>
