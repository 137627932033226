<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view name="first" />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
export default {
  name: "App",
  data() {
    return {
      zh_CN,
      bol: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      this.bol = false;
      this.$nextTick(function () {
        this.bol = true;
      });
    },
  },
};
</script>

<style>
body {
  background: #f0f2f5 !important;
}
.nav {
  width: 100%;
  height: 100%;
}
.nav .nav-item {
  display: inline-block;
  width: 400px;
  margin-bottom: 20px;
}
.nav .search-button {
  margin-bottom: 20px;
}
</style>
