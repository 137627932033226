<template>
  <div class="powerRole">
    <div class="nav">
      <div class="nav-item">
        客户端：
        <a-select
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          v-model="selectClientId"
          placeholder="请选择客户端"
          style="width: 300px"
          @change="selectClientIdChange"
        >
          <a-select-option v-for="item in clientIdList" :key="item.clientId">{{
            item.clientName
          }}</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="btn-c">
      <a-button type="primary" @click="query(1)" :disabled="queryDisabled"
        >查询</a-button
      >
      <a-button type="primary" @click="addUser" style="margin-left: 20px"
        >新增角色</a-button
      >
      <a-button type="primary" @click="menuAdmin" style="margin-left: 20px"
        >菜单管理</a-button
      >
      <a-button type="primary" @click="powerAdmin" style="margin-left: 20px"
        >权限管理</a-button
      >
    </div>
    <!-- 用户列表 -->
    <a-table
      :columns="userColumns"
      :dataSource="userTableData"
      :pagination="userPagination"
      :loading="loadingTable"
      size="middle"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="editClick(record)">编辑</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="menuClick(record)">菜单设置</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="powerClick(record)">权限设置</a>
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          @click="deleteClick(record)"
          style="color: #ff4d4f"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addUserShow"
      :maskClosable="false"
      :afterClose="addUserClose"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端"
          required
        >
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="addUserForm.clientId"
            placeholder="请选择客户端"
          >
            <a-select-option
              v-for="item in clientIdList"
              :key="item.clientId"
              >{{ item.clientName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="角色编码	"
          required
        >
          <a-input
            v-model="addUserForm.roleCode"
            placeholder="请输入角色编码	"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="角色名称"
          required
        >
          <a-input
            v-model="addUserForm.roleName"
            placeholder="请输入角色名称"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="备注"
          required
        >
          <a-input
            v-model="addUserForm.remark"
            placeholder="请输入备注"
          ></a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addUserShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 新增or编辑菜单 -->
    <a-modal
      :title="addMenuTitle"
      v-model="addMenusVisible"
      :maskClosable="false"
      :afterClose="addMenusClose"
      class="action-class"
      width="500px"
    >
      <a-form :form="addMenuForm">
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            v-model="addMenuForm.name"
            placeholder="请输入名称"
          ></a-input>
        </a-form-item>
        <a-form-item
          label="编码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-model="addMenuForm.code"
            placeholder="请输入编码"
          ></a-input>
        </a-form-item>
        <a-form-item
          label="图标:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-model="addMenuForm.class"
            placeholder="请输入图标"
          ></a-input>
        </a-form-item>
        <a-form-item
          label="url:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="addMenuForm.indexUrl" placeholder="请输入url" />
        </a-form-item>
        <a-form-item
          label="启用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="!isAddMenus"
        >
          <a-switch v-model="addMenuForm.enabled" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addMenusVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addMenus_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 新增菜单 -->
    <a-modal
      :title="titleAdmin"
      v-model="addMenuVisible"
      :maskClosable="false"
      :afterClose="addMenuClose"
      class="tree_style"
      :footer="null"
      width="600px"
    >
      <a-button type="primary" @click="rootAdd"> 新增 </a-button>
      <a-tree
        class="draggable-tree"
        draggable
        :tree-data="menuData"
        @dragenter="onDragEnter"
      >
        <!-- @drop="onDrop" -->
        <template slot="custom" slot-scope="item">
          <span class="tree_item_title">
            <div>{{ item.name }}</div>
            <div style="width: 190px">
              <a
                href="javascript:;"
                @click="menuAdd(item)"
                v-if="item.appendShow"
                >追加</a
              >
              <a-divider type="vertical" v-if="item.appendShow" />
              <i style="margin-left: 45px" v-if="!item.appendShow"></i>
              <a href="javascript:;" @click="menuModfiy(item)">编辑</a>
              <a-divider type="vertical" />
              <a
                href="javascript:;"
                @click="menuDelete(item)"
                style="color: #ff4d4f"
                >删除</a
              >
            </div>
          </span>
        </template>
      </a-tree>
    </a-modal>
    <!-- 菜单设置 -->
    <a-modal
      :title="setDialogTitle"
      v-model="menuShow"
      :maskClosable="false"
      :afterClose="menuClose"
    >
      <a-tree
        class="draggable-tree"
        v-model="checkedKeys"
        checkable
        :auto-expand-parent="autoExpandParent"
        :selected-keys="selectedKeys"
        :expanded-keys="expandedKeys"
        @expand="onExpand"
        @select="onSelect"
        :tree-data="menuData"
      >
      </a-tree>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="menuShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="menu_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "powerRole",
  data() {
    return {
      title: "",
      id: "",
      setDialogTitle: "",
      setDialogType: "",
      checkedKeys: [],
      autoExpandParent: true,
      selectedKeys: [],
      expandedKeys: [],
      menuData: [],
      roleId: "",
      type: "",
      // ------------------------
      addMenuVisible: false,
      addMenusVisible: false,
      isAddMenus: false,
      addMenuForm: {
        name: "",
        code: "",
        class: "",
        indexUrl: "",
        pid: "",
        // order: "",
        resourceId: "",
        enabled: true,
      },
      addMenuTitle: "",
      titleAdmin: "",
      isNext: false,
      // -------end
      loading: false,
      loadingTable: false,
      menuShow: false,
      userColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "客户端名称",
          dataIndex: "clientId",
        },
        {
          title: "角色名称",
          dataIndex: "roleName",
        },
        {
          title: "角色编码",
          dataIndex: "roleCode",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userTableData: [],
      userPagination: {},
      pageNo: 1,
      addUserShow: false,
      addUserForm: {
        roleCode: "",
        roleName: "",
        remark: "",
        clientId: undefined,
      },
      clientIdList: [],
      selectClientId: undefined,
    };
  },
  computed: {
    queryDisabled() {
      if (this.selectClientId) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getClientList();
  },
  methods: {
    // 选择客户端
    selectClientIdChange(val) {
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        clientId: this.selectClientId,
      };
      this.getUserClientRelationList(data);
    },
    // 查询表格数据
    getUserClientRelationList(data) {
      this.loadingTable = true;
      this.$http
        .get("/role/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.userTableData = list;
            this.userPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    changePage(index) {
      this.query(index);
    },
    // 点击编辑
    editClick(val) {
      this.title = "编辑";
      this.addUserShow = true;
      this.id = val.id;
      this.addUserForm.clientId = val.clientId;
      this.addUserForm.roleCode = val.roleCode;
      this.addUserForm.roleName = val.roleName;
      this.addUserForm.remark = val.remark;
    },
    // 点击新增
    addUser() {
      this.title = "新增";
      this.addUserShow = true;
      this.addUserForm.clientId = this.selectClientId;
    },
    // 确定新增
    addUser_submit() {
      this.loading = true;
      if (this.title === "新增") {
        let data = {
          clientId: this.addUserForm.clientId,
          roleCode: this.addUserForm.roleCode,
          roleName: this.addUserForm.roleName,
          remark: this.addUserForm.remark,
        };
        this.$http
          .json_post("/role/addRole", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.addUserShow = false;
              this.$message.success("新增成功");
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else if (this.title === "编辑") {
        let data = {
          id: this.id,
          roleCode: this.addUserForm.roleCode,
          roleName: this.addUserForm.roleName,
          remark: this.addUserForm.remark,
        };
        this.$http
          .json_post("/role/editRole", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.addUserShow = false;
              this.$message.success("新增成功");
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭新增
    addUserClose() {
      this.addUserForm = {
        roleCode: "",
        roleName: "",
        remark: "",
        clientId: undefined,
      };
    },
    // 点击菜单设置
    menuClick(val) {
      this.menuShow = true;
      this.setDialogTitle = "【" + val.roleName + "】菜单设置";
      this.type = "MENU";
      this.roleId = val.id;
      this.isNext = true;
      this.inquirePowerList();
    },
    // 点击权限设置
    powerClick(val) {
      this.menuShow = true;
      this.setDialogTitle = "【" + val.roleName + "】权限设置";
      this.type = "POWER";
      this.roleId = val.id;
      this.isNext = true;
      this.inquirePowerList();
    },
    // 查询所有资源
    inquirePowerList() {
      let data = {
        type: this.type,
        clientId: this.selectClientId,
      };
      this.$http.get("/alphaResource/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            item.scopedSlots = { title: "custom" };
            item.title = item.name;
            item.key = item.id;
            item.children = item.subResourceList;
            item.appendShow = true;
            if (item.children) {
              item.children.forEach((menu) => {
                menu.appendShow = false;
                menu.scopedSlots = { title: "custom" };
                menu.title = menu.name;
                menu.key = menu.id;
              });
            } else {
              item.children = [];
            }
          });
          this.menuData = list;
          if (this.isNext) {
            this.inquireRoleMenus(data);
          }
        }
      });
    },
    // 查询对应角色资源
    inquireRoleMenus(data) {
      data.id = this.roleId;
      this.$http.get("/role/roleResources", data).then((res) => {
        let menuId = [];
        let list = res.data;
        list.map((i) => {
          if (i.subResourceList) {
            i.subResourceList.map((j) => {
              menuId.push(j.id);
            });
          } else {
            menuId.push(i.id);
          }
        });
        this.expandedKeys = menuId;
        this.checkedKeys = menuId;
      });
    },
    // 菜单-权限设置确定
    menu_submit() {
      let resouceIds = this.checkedKeys.join(",");
      let data = {
        id: this.roleId,
        resourceIds: this.checkedKeys.join(","),
        type: this.type,
      };
      if (!resouceIds) {
        data.deleteAll = true;
      }
      this.loading = true;
      this.$http
        .json_post("/role/updateRoleResource", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("设置成功");
            this.menuShow = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭菜单-权限
    menuClose() {
      this.setDialogTitle = "";
      this.autoExpandParent = true;
      this.menuData = [];
      this.checkedKeys = [];
    },
    // 展开/收起节点时触发
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    // 点击树节点触发
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info);
      // this.selectedKeys = selectedKeys;
    },
    // 选项搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 查询客户端列表
    getClientList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$http.post("/client/clientList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.clientIdList = list;
          if (list.length > 0) {
            this.selectClientId = list[0].clientId;
            this.query(1);
          }
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除",
        content: "是否删除【" + val.roleName + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/role/deleteRole", {
              id: val.id,
            })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.query();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 关闭新增菜单
    addMenuClose() {
      this.menuData = [];
      this.autoExpandParent = true;
    },
    //开始拖拽时调用
    onDragEnter(info) {
      // console.log(info);
      // expandedKeys 需要受控时设置
      // this.expandedKeys = info.expandedKeys
    },
    //drop 触发时调用
    // onDrop(info) {
    //   const dropKey = info.node.eventKey;
    //   const dragKey = info.dragNode.eventKey;
    //   const dropPos = info.node.pos.split("-");
    //   const dropPosition =
    //     info.dropPosition - Number(dropPos[dropPos.length - 1]);
    //   const loop = (data, key, callback) => {
    //     data.forEach((item, index, arr) => {
    //       if (item.key === key) {
    //         return callback(item, index, arr);
    //       }
    //       if (item.children) {
    //         return loop(item.children, key, callback);
    //       }
    //     });
    //   };
    //   const data = [...this.menuData];

    //   // Find dragObject
    //   let dragObj;
    //   loop(data, dragKey, (item, index, arr) => {
    //     arr.splice(index, 1);
    //     dragObj = item;
    //   });
    //   if (!info.dropToGap) {
    //     // Drop on the content
    //     loop(data, dropKey, (item) => {
    //       item.children = item.children || [];
    //       // where to insert 示例添加到尾部，可以是随意位置
    //       item.children.push(dragObj);
    //     });
    //   } else if (
    //     (info.node.children || []).length > 0 && // Has children
    //     info.node.expanded && // Is expanded
    //     dropPosition === 1 // On the bottom gap
    //   ) {
    //     loop(data, dropKey, (item) => {
    //       item.children = item.children || [];
    //       // where to insert 示例添加到尾部，可以是随意位置
    //       item.children.unshift(dragObj);
    //     });
    //   } else {
    //     let ar;
    //     let i;
    //     loop(data, dropKey, (item, index, arr) => {
    //       ar = arr;
    //       i = index;
    //     });
    //     if (dropPosition === -1) {
    //       ar.splice(i, 0, dragObj);
    //     } else {
    //       ar.splice(i + 1, 0, dragObj);
    //     }
    //   }

    //   let data_new = {
    //     resourceId: info.dragNode.dataRef.id,
    //     name: info.dragNode.dataRef.name,
    //     pid: info.dragNode.dataRef.pid,
    //     extraMsg: info.dragNode.dataRef.extraMsg,
    //     enabled: info.dragNode.dataRef.enabled,
    //     order: "",
    //   };
    //   this.menuData.forEach((c, i) => {
    //     if (dragKey == c.id) {
    //       data_new.order = i + 1;
    //       data_new.pid = 0;
    //       c.appendShow = true;
    //     } else {
    //       if (c.children) {
    //         c.children.forEach((k, j) => {
    //           if (dragKey == k.id) {
    //             data_new.order = j + 1;
    //             data.pid = info.dragNode.dataRef.id;
    //             k.appendShow = false;
    //           }
    //         });
    //       }
    //     }
    //   });
    //   this.menuData = data;
    //   api.userUpdateResource(data_new).then((res) => {
    //     if (res.result === 200) {
    //       this.$message.success("调整成功");
    //     }
    //   });
    // },

    // 根目录新增
    rootAdd() {
      this.addMenuTitle = "新增";
      this.addMenusVisible = true;
    },
    // 点击追加
    menuAdd(val) {
      console.log(val);
      this.addMenuTitle = "【" + val.name + "】追加";
      this.addMenuForm.pid = val.id;
      // this.addMenuForm.order = val.children.length + 1;
      this.addMenusVisible = true;
      this.isAddMenus = true;
    },
    // 点击编辑---菜单
    menuModfiy(val) {
      this.addMenuTitle = "【" + val.name + "】编辑";
      this.addMenuForm.name = val.name;
      this.addMenuForm.code = val.code;
      this.addMenuForm.pid = val.pid;
      this.addMenuForm.resourceId = val.id;
      // this.addMenuForm.order = val.order;
      this.addMenuForm.enabled = val.enabled;
      this.addMenuForm.indexUrl = val.url;
      this.addMenuForm.class = val.icon;
      this.addMenusVisible = true;
      this.isAddMenus = false;
    },
    // 确定菜单追加
    addMenus_submit() {
      if (this.addMenuTitle === "新增") {
        let data = {
          clientId: this.selectClientId,
          name: this.addMenuForm.name,
          code: this.addMenuForm.code,
          pid: "",
          icon: this.addMenuForm.class,
          url: this.addMenuForm.indexUrl,
          type: this.type,
        };
        if (!this.addMenuForm.name) {
          this.$message.warning("信息不完整");
          return;
        }
        this.loading = true;
        this.$http
          .json_post("/alphaResource/addResource", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.addMenusVisible = false;
              this.inquirePowerList();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        if (this.isAddMenus) {
          let data = {
            clientId: this.selectClientId,
            name: this.addMenuForm.name,
            code: this.addMenuForm.code,
            pid: this.addMenuForm.pid,
            icon: this.addMenuForm.class,
            url: this.addMenuForm.indexUrl,
            // order: this.addMenuForm.order,
            // extraMsg: JSON.stringify(extraMsg),
            type: this.type,
          };
          if (!this.addMenuForm.name) {
            this.$message.warning("信息不完整");
            return;
          }
          this.loading = true;
          this.$http
            .json_post("/alphaResource/addResource", data)
            .then((res) => {
              this.loading = false;
              if (res.result === 200) {
                this.$message.success("追加成功");
                this.addMenusVisible = false;
                this.inquirePowerList();
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          let data = {
            id: this.addMenuForm.resourceId,
            name: this.addMenuForm.name,
            code: this.addMenuForm.code,
            pid: this.addMenuForm.pid,
            icon: this.addMenuForm.class,
            url: this.addMenuForm.indexUrl,
            // order: this.addMenuForm.order,
            enabled: this.addMenuForm.enabled,
            // extraMsg: JSON.stringify(extraMsg),
            type: this.type,
          };
          this.loading = true;
          this.$http
            .json_post("/alphaResource/editResource", data)
            .then((res) => {
              this.loading = false;
              if (res.result === 200) {
                this.$message.success("编辑成功");
                this.addMenusVisible = false;
                this.inquirePowerList();
              }
            })
            .catch((err) => {
              this.loading = false;
            });
          // api
          //   .userUpdateResource(data)
          //   .then((res) => {
          //     this.loading = false;
          //     if (res.result === 200) {
          //       this.$message.success("编辑成功");
          //       this.inquirePowerList();
          //       this.addMenusVisible = false;
          //     }
          //   })
          //   .catch((err) => {
          //     this.loading = false;
          //   });
        }
      }
    },
    // 关闭新增菜单编辑
    addMenusClose() {
      this.addMenuForm = {
        name: "",
        code: "",
        class: "",
        indexUrl: "",
        pid: "",
        // order: "",
        resourceId: "",
        enabled: true,
      };
      this.loading = false;
    },
    // 点击删除--菜单
    menuDelete(val) {
      let data = {
        id: val.id,
      };
      this.$confirm({
        title: "确认删除",
        content: "是否删除【" + val.name + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/alphaResource/deleteResource", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquirePowerList();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 点击菜单管理
    menuAdmin() {
      this.addMenuVisible = true;
      this.titleAdmin = "【" + this.selectClientId + "】菜单管理";
      this.type = "MENU";
      this.isNext = false;
      this.inquirePowerList();
    },
    // 点击权限管理
    powerAdmin() {
      this.addMenuVisible = true;
      this.titleAdmin = "【" + this.selectClientId + "】权限管理";
      this.type = "POWER";
      this.isNext = false;
      this.inquirePowerList();
    },
  },
};
</script>
<style lang="scss" scoped>
.powerRole .btn-c {
  margin-bottom: 20px;
}
.tree_style {
  & ::v-deep .ant-tree-node-content-wrapper {
    width: 100%;
  }
}
.tree_item_title {
  display: flex;
  justify-content: space-between;
  .tree_item_radio {
    font-size: 12px;
    display: inline-block;
    .ant-radio-wrapper {
      font-size: 12px !important;
      & ::v-deep .ant-radio-inner {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        &::after {
          width: 6.5px;
          height: 6.5px;
        }
      }
    }
  }
}
</style>
