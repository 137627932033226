<template>
  <div class="powerManagement">
    <div class="nav">
      <div class="nav-item">
        客户端：
        <a-select
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          v-model="selectClientId"
          placeholder="请选择客户端"
          style="width: 300px"
          @change="selectClientIdChange"
        >
          <a-select-option v-for="item in clientIdList" :key="item.clientId">{{
            item.clientName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="nav-item">
        用户：
        <a-select
          show-search
          option-filter-prop="children"
          :filter-option="false"
          v-model="selectUserLoginName"
          placeholder="请选择用户"
          allowClear
          style="width: 300px"
          @search="searchUserName"
        >
          <a-select-option
            v-for="item in parentUserLoginNameList"
            :key="item.loginName"
            >{{ item.userName }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="btn-c">
      <a-button type="primary" @click="query(1)" :disabled="queryDisabled"
        >查询</a-button
      >
      <a-button @click="addUser" style="margin-left: 20px">新增</a-button>
    </div>
    <!-- 用户列表 -->
    <a-table
      :columns="userColumns"
      :dataSource="userTableData"
      :pagination="userPagination"
      :loading="loadingTable"
      size="middle"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="editClick(record)">编辑</a>
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          @click="deleteClick(record)"
          style="color: #ff4d4f"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addUserShow"
      :maskClosable="false"
      :afterClose="addUserClose"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="客户端"
          required
        >
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="addUserForm.clientId"
            placeholder="请选择客户端"
            :disabled="editShow"
          >
            <a-select-option
              v-for="item in clientIdList"
              :key="item.clientId"
              >{{ item.clientName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="上级用户"
          required
        >
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="false"
            v-model="addUserForm.parentUserLoginName"
            placeholder="请选择用户"
            allowClear
            :disabled="editShow"
            @search="searchUserName"
          >
            <a-select-option
              v-for="item in parentUserLoginNameList"
              :key="item.loginName"
              >{{ item.userName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="用户"
          required
        >
          <a-select
            show-search
            mode="multiple"
            option-filter-prop="children"
            :filter-option="false"
            v-model="addUserForm.userLoginName"
            placeholder="请选择用户"
            allowClear
            :disabled="editShow"
            @search="searchUserName"
          >
            <a-select-option
              v-for="item in parentUserLoginNameList"
              :key="item.loginName"
              >{{ item.userName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="角色"
          required
        >
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="addUserForm.roleId"
            placeholder="请选择角色"
          >
            <a-select-option v-for="item in roleIdList" :key="item.id">{{
              item.roleName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addUserShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "powerManagement",
  data() {
    return {
      editId: "",
      editShow: false,
      loading: false,
      loadingTable: false,
      userColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "key",
          width: 60,
        },
        {
          title: "客户端名称",
          dataIndex: "clientId",
        },
        {
          title: "用户名称",
          dataIndex: "userName",
        },
        {
          title: "上级用户",
          dataIndex: "parentUserName",
        },
        {
          title: "角色",
          dataIndex: "roleName",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userTableData: [],
      userPagination: {},
      pageNo: 1,
      addUserShow: false,
      addUserForm: {
        // roleCode: "",
        clientId: undefined,
        roleId: undefined,
        parentUserLoginName: undefined,
        userLoginName: [],
      },
      clientIdList: [],
      roleIdList: [],
      parentUserLoginNameList: [],
      selectClientId: undefined,
      selectUserLoginName: undefined,
    };
  },
  computed: {
    queryDisabled() {
      if (this.selectClientId) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getClientList();
    this.getUserList();
  },
  methods: {
    // 选择客户端
    selectClientIdChange(val) {
      this.query(1);
      this.getRoleIdList();
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        clientId: this.selectClientId,
        userLoginName: this.selectUserLoginName,
      };
      this.getUserClientRelationList(data);
    },
    // 查询表格数据
    getUserClientRelationList(data) {
      this.loadingTable = true;
      this.$http
        .get("/manager/userClientRelationList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.userTableData = list;
            this.userPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    changePage(index) {
      this.query(index);
    },
    // 点击新增
    addUser() {
      this.addUserShow = true;
      this.addUserForm.clientId = this.selectClientId;
      this.editShow = false;
    },
    // 点击编辑
    editClick(val) {
      this.addUserShow = true;
      this.addUserForm.clientId = val.selectClientId;
      this.addUserForm.userLoginName = val.userLoginName;
      this.addUserForm.parentUserLoginName = val.parentUserLoginName;
      if (val.roleId === -1) {
        this.addUserForm.roleId = undefined;
      } else {
        this.addUserForm.roleId = val.roleId;
      }
      this.editShow = true;
      this.editId = val.id;
    },
    // 确定新增
    addUser_submit() {
      let data = {
        clientId: this.addUserForm.clientId,
        // userLoginName: this.addUserForm.userLoginName.join(","),
        parentUserLoginName: this.addUserForm.parentUserLoginName,
        // roleCode: this.addUserForm.roleCode,
        roleId: this.addUserForm.roleId,
      };
      console.log(data.userLoginName, this.addUserForm.userLoginName);
      if (this.editShow) {
        this.loading = true;
        data.id = this.editId;
        data.userLoginName = this.addUserForm.userLoginName;
        this.$http
          .json_post("/manager/editUserClientRelation", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.addUserShow = false;
              this.$message.success("新增成功");
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.loading = true;
        (data.userLoginName = this.addUserForm.userLoginName.join(",")),
          this.$http
            .json_post("/manager/addUserClientRelation", data)
            .then((res) => {
              this.loading = false;
              if (res.result === 200) {
                this.addUserShow = false;
                this.$message.success("新增成功");
                this.query(1);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
      }
    },
    // 关闭新增
    addUserClose() {
      this.addUserForm = {
        // roleCode: "",
        roleId: undefined,
        clientId: undefined,
        parentUserLoginName: undefined,
        userLoginName: [],
      };
    },
    // 选项搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 查询客户端列表
    getClientList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$http.post("/client/clientList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.clientIdList = list;
          if (list.length > 0) {
            this.selectClientId = list[0].clientId;
            this.query(1);
          }
        }
      });
    },

    // 查询角色列表
    getRoleIdList() {
      let data = {
        clientId: this.selectClientId,
      };
      this.$http.post("/role/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.roleIdList = list;
        }
      });
    },
    searchUserName(val) {
      this.parentUserLoginNameList.splice(0);
      if (val !== "") {
        this.getUserList(val);
      }
    },
    // 查询用户列表
    getUserList(val) {
      let data = {
        pageNo: 1,
        pageSize: 500,
        enabled: true,
        userLoginName: val,
      };
      this.$http.post("/manager/userList", data).then((res) => {
        if (res.result === 200) {
          this.parentUserLoginNameList.push(...res.data.records);
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除",
        content: "是否删除【" + val.userName + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/manager/deleteUserClientRelation", {
              id: val.id,
            })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.query();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
  },
};
</script>
<style scoped>
.powerManagement .btn-c {
  margin-bottom: 20px;
}
</style>
