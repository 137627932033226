<template>
  <div class="invalid">
    <div>
      <div class="invalid-img">
        <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg">
      </div>
      <div class="invalid-text">
        <div class="text">401</div>
        <div class="desc">无权访问,请联系管理员</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'invalid',
  data () {
    return {

    }
  }
}
</script>
<style scoped>
.invalid {
  height: 100%;
  background: #f0f2f5;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.invalid-img {
  display: inline-block;
  padding-right: 52px;
}
.invalid-img img{
  vertical-align: middle;
  height: 360px;
  max-width: 430px;
}
.invalid-text {
  display: inline-block;
}
.invalid-text .text {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.invalid-text .desc {
  color: rgba(0,0,0,.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
</style>
