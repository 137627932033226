var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('a-layout',{attrs:{"id":"components-layout-demo-custom-trigger"}},[_c('a-layout-sider',{style:({
        overflow: 'auto',
        height: '100%',
        position: 'fixed',
        left: 0,
        zIndex: '1000',
      }),attrs:{"trigger":null,"collapsible":"","collapsedWidth":_vm.isPc === true ? 80 : 0},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('a-menu',{attrs:{"theme":"dark","mode":"inline","selectedKeys":[_vm.selectedKeys],"openKeys":_vm.openKeys},on:{"openChange":_vm.onOpenChange,"click":_vm.selectClick}},_vm._l((_vm.list),function(item){return _c('a-sub-menu',{key:item.url},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{attrs:{"type":item.iconType}}),_c('span',[_vm._v(_vm._s(item.name))])],1),_vm._l((item.children),function(menu){return _c('a-menu-item',{key:menu.url},[_vm._v(" "+_vm._s(menu.name)+" ")])})],2)}),1)],1),_c('a-layout-header',{class:{
        isheader: !_vm.collapsed,
        onheader: _vm.collapsed,
        onheaderPc: _vm.isPcShow,
      },staticStyle:{"background":"#fff","padding":"0 30px 0 0","position":"fixed","width":"100%","z-index":"1"}},[_c('div',{staticClass:"header-cc"},[_c('div',[_c('a-icon',{staticClass:"trigger",attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":function () { return (_vm.collapsed = !_vm.collapsed); }}}),_c('span',{staticClass:"title-theme"},[_vm._v("后台管理系统")])],1),_c('a-dropdown',{staticClass:"user-name-cc"},[_c('span',{staticStyle:{"cursor":"pointer","border-left":"1px solid #d3d3d3","padding-left":"10px","font-size":"16px"}},[(_vm.loginAvatarUrl)?_c('a-avatar',{staticClass:"avatar",attrs:{"size":"small","shape":"circle","src":_vm.loginAvatarUrl}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.loginNickName))])],1),_c('a-menu',{staticStyle:{"width":"150px"},attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',[_c('a',{staticStyle:{"display":"inline-block","text-align":"center","width":"100%","padding":"0","margin":"0"},attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.logOut()}}},[_c('a-icon',{attrs:{"type":"poweroff"}}),_c('span',[_vm._v("退出登录")])],1)])],1)],1)],1)]),_c('a-layout',{class:{
        isContent: !_vm.collapsed,
        onContent: _vm.collapsed,
        onContentPc: _vm.isPcShow,
      },style:({ padding: '24px 16px' })},[_c('div',{staticStyle:{"background":"rgb(255, 255, 255)","padding":"24px"}},[(_vm.isComponent)?_c('router-view',{attrs:{"name":"second","loginUserName":_vm.loginName}}):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }