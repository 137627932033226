import { render, staticRenderFns } from "./powerRole.vue?vue&type=template&id=7fd3a912&scoped=true&"
import script from "./powerRole.vue?vue&type=script&lang=js&"
export * from "./powerRole.vue?vue&type=script&lang=js&"
import style0 from "./powerRole.vue?vue&type=style&index=0&id=7fd3a912&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fd3a912",
  null
  
)

export default component.exports