import http from "./axios";

// 查询列表
const queryPage = ({ name, environment }) => {
  return http.get("/entrance/entrancePage", {
    name,
    environment,
  });
};

// 添加
const addData = ({
  name,
  ssoUri,
  redirectUri,
  enabled,
  type,
  environment,
  remark,
  icon,
}) => {
  return http.json_post("/entrance/addEntrance", {
    name,
    ssoUri,
    redirectUri,
    enabled,
    type,
    environment,
    remark,
    icon,
  });
};

// 编辑
const editData = ({
  id,
  name,
  ssoUri,
  redirectUri,
  enabled,
  type,
  remark,
  environment,
  icon,
}) => {
  return http.json_post("/entrance/editEntrance", {
    id,
    name,
    ssoUri,
    redirectUri,
    enabled,
    type,
    remark,
    environment,
    icon,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/entrance/deleteEntrance", {
    id,
  });
};

export default {
  deleteData,
  queryPage,
  addData,
  editData,
};
