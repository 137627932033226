//封装公共代码

const common = {

}


//时间格式：yyyy-MM-dd HH:mm:ss
common.transformTime = function (time) {
	var time = new Date(time);
	var y = time.getFullYear();
	var m = time.getMonth() + 1;
	var d = time.getDate();
	var h = time.getHours();
	var mm = time.getMinutes();
	var s = time.getSeconds();
	function add0(m) {
		return m < 10 ? '0' + m : m;
	};
	return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

// 随机生成数字+大小写字母
common.randomStr = function (randomFlag, min, max) {
	// randomFlag：是否任意长度 
	// min：任意长度最小位[固定位数]
	// max：任意长度最大位
	//生成3-32位随机串：randomStr(true, 3, 32)
	//生成43位随机串：randomStr(false, 43)
	let str = "", range = min, index = null,
		arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

	if (randomFlag) {// 随机产生
		range = Math.round(Math.random() * (max - min)) + min;
	}

	for (let i = 0; i < range; i++) {
		index = Math.round(Math.random() * (arr.length - 1));
		str += arr[index];
	}
	return str;
}

//获取路由
common.getUrl = function () {

}

// 测试
common.uniq = function (arr) {


}




// this.$confirm('此操作将删除该项数据, 是否继续?', '提示', {
// 					confirmButtonText: '确定',
// 					cancelButtonText: '取消',				
// 					type: 'warning'
// 				}).then(() => {//删除操作								
// 																										
// 				}).catch(() => {//取消
// 		
// 				});				 












export default common;
